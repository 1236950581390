/* eslint-disable no-undef */

import { useLocation } from 'react-router-dom';

const useQueryStringParams = (...args: string[]) => {
  let searchParams = new URLSearchParams(useLocation().search);
  if (args.length === 0) return searchParams;
  else {
    let wantedParams = {};
    for (let i = 0; i < args.length; i++) {
      let param = args[i];
      if (searchParams.has(param)) wantedParams[param] = searchParams.get(param);
    }
    return wantedParams;
  }
};

// Capitalize
const capitalize = (string) => {
  let charSquence = string.split(' ');
  var generatedString: string[] = [];
  charSquence.forEach((element) => {
    generatedString.push(element.charAt(0).toUpperCase() + element.slice(1));
  });

  return generatedString.join(' ');
};

// Get initials of a name, if glue=true the function is going to return a string, if false an array
const getInitials = (name: string, glue: boolean = true) => {
  if (!name) return '';

  let initials = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);

  if (glue && initials != null) {
    return initials.join('').toUpperCase();
  }

  return initials;
};

// Format price
const formatPrice = (number) => {
  const fnumber = parseFloat(number);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(fnumber);
};

const truncateText = (
  text: string,
  length: number,
  useWordBoundary: boolean = true
): string => {
  if (text.length <= length) {
    return text;
  }

  const maxSubString = text.substr(0, length - 1);

  return useWordBoundary
    ? `${maxSubString.substr(0, maxSubString.lastIndexOf(' '))}...`
    : `${maxSubString}...`;
};

export { capitalize, getInitials, formatPrice, truncateText, useQueryStringParams };
